@import url('https://fonts.googleapis.com/css2?family=Grenze+Gotisch:wght@100;200;400&family=Kings&family=Lora&display=swap')
@import 'react-big-calendar/lib/sass/styles'

$grenze: 'Grenze Gotisch', cursive
$kings: 'Kings', cursive
$lora: 'Lora', serif

$red: #E11A14
$pink: #FAC8C6
$blue: #B3D5F9
$yellow: #F3D053
$black: #312B3B

$tablet: 767px
$desktop: 1023px

/////// Ubiquitous States
.hidden
  display: none

.scroll-menu
  transform: translateX(0px)

.scroll-back-menu
  transform: translateX(-242px)

.fade
  opacity: 0
  z-index: 0

.fade-reverse
  opacity: 1
  z-index: 10

.bold
  font-weight: 400 !important

.red
  color: $red !important

.pointer
  cursor: pointer

.no-padding-bottom
  padding-bottom: 0 !important

//////// Layouts and Styles  
body
  background-color: $pink

.App
  display: flex
  justify-content: flex-start
  
  #headline
    margin: 5px 5px 10px
    font-size: 40px
    @media screen and (min-width: $desktop)
      font-size: 50px

#nav
  padding: 20px 10px
  border-right: solid 1px red
  background-color: $red
  height: 100vh
  z-index: 2
  font-family: $grenze
  transition: 0.6s ease-out
  position: fixed
  overflow-x: hidden
  overflow-y: auto
  @media screen and (min-width: $tablet)
    width: 250px

  #blocker
    width: 300px
    height: 100vh
    background-color: $red
    position: absolute
    right: 10px
    transition: opacity 1s, z-index 1s
    clip-path: polygon(0% 0%, 100% calc(0% + 45px), 100% 100%, 0% 100%)

  div#mobile-nav
    @media screen and (min-width: $tablet)
      display: none

    .bars, .close
      position: fixed
      right: 10px

  #full-menu
    max-width: 280px
    width: 100%
    padding: 15px 0 0
    margin-bottom: 30px

    ul
      padding: 0

      a:hover, a:visited, a:link, a:active
        text-decoration: none
        color: $black

      li
        list-style: none
        vertical-align: middle
        margin-bottom: 20px
        position: relative
        
        .full-item, .sub-item
          background-color: $pink
          padding: 0 10px
          display: flex
          align-items: center
          text-align: center
          position: relative

        .full-item
          height: 35px
          font-weight: 400
          font-size: 27px
          clip-path: polygon(0% 0%, 97.2% 0%, 100% 100%, 2.8% 100%)

        .sub-item
          height: 26px
          font-size: 20px
          font-weight: 200
          clip-path: polygon(0% 0%, 97.5% 0%, 99% 100%, 1.5% 100%)
          text-overflow: ellipsis
          display: -webkit-box
          -webkit-line-clamp: 1 /* number of lines to show */
          -webkit-box-orient: vertical
          -webkit-box-pack: start
          margin-top: -3px
          vertical-align: top
          

        .item-wrapper
          position: relative
          transform: rotate(10deg)          

        .sub-menu-item
          height: 20px
          margin-left: 15px
          margin-top: 3px
          margin-bottom: 18px

          .menu-background
            clip-path: polygon(0% 0%, 97.5% 0%, 99% 100%, 1.5% 100%)

        .menu-background
          background-color: $black
          right: -3px
          left: -3px
          top: -3px
          bottom: -3px
          position: absolute
          z-index: -1
          clip-path: polygon(0% 0%, 97.2% 0%, 100% 100%, 2.8% 100%)

  #img-cont
    width: 100%
    display: flex
    justify-content: center



#body
  z-index: 1
  color: $black
  position: absolute
  right: 10px
  @media screen and (max-width: $tablet)
    left: 59px
  @media screen and (min-width: $tablet)
    left: 281px
  @media screen and (min-width: $desktop)
    max-width: 950px

  div#content-cont
    display: flex
    flex-direction: column

  h1, h2, h3, h4
    font-family: $grenze
    margin: 0

  h2
    font-size: 36px

  h3
    font-size: 32px

  h4
    font-size: 22px
    font-weight: 300

  p, li
    font-family: $lora
    @media screen and (min-width: $desktop)
      font-size: 17px

  #home 
    div.home-section
      overflow: hidden

    div.home-section:nth-child(odd)
      background-color: $blue
      img
        @media screen and (min-width: $desktop)
          float: left
          margin: 0 20px 0 5px

    div.home-section:nth-child(even) 
      background-color: $yellow
      img
        @media screen and (min-width: $desktop)
          float: right
          margin: 0 5px 0 20px

  div.info-cont
    margin: 5px 0 15px

    p
      margin: 0 0 5px

  #post
    padding: 0 10px 0 10px
    border: 1px solid $black
    margin: 0 10px 10px 0
    border-radius: 5px
    background-color: $yellow

    img
      max-width: 100%

  #home, #posts, #about
    width: 100%
    flex-grow: 100

    .year-section, .about-section, #calendar, #map
      background-color: $blue

    .home-section, .year-section, .about-section, #calendar, #map
      padding: 0 10px 10px
      border: 1px solid $black
      margin: 0 0 10px 0
      border-radius: 5px
      @media screen and (min-width: $desktop)
        padding-bottom: 20px

      form.mailchimp
        display: flex
        flex-direction: column
        justify-content: flex-start
        height: 100px

        *
          margin-bottom: 15px

        input
          font-size: 15px
          font-family: $grenze

        button
          font-family: $lora
          background-color: $pink
          border: 1px solid $black
          border-radius: 5px
          height: 29px
          cursor: pointer

        div.msg-alert 
          p
            font-family: $lora
            color: $black !important
            margin-top: 0

      .home-title
        margin: 8px 0

      #header-cont
        display: flex
        justify-content: flex-start
        align-items: center
        gap: 20px
        // max-width: 450px

        #legend
          display: flex
          justify-content: space-between
          align-items: center
          height: 30px
          max-width: 85px
          width: 100%
          border: 1px solid $black
          border-radius: 5px
          padding: 0 10px
          font-weight: bold

          *
            margin: 0

      img
        max-width: 100%
        @media screen and (min-width: $desktop)
          max-width: 60%
          // margin: 0 20px 0 0
      
      p
        @media screen and (min-width: $desktop)
          text-align: justify
          margin-right: 20px
          font-size: 16px

      a:hover, a:visited, a:link, a:active
        // text-decoration: none
        color: $black

      ul
        padding: 0
        li
          list-style: none
          margin-bottom: 10px

    .section-header
      display: flex
      justify-content: space-between
      align-items: center

    #calendar, #map
      font-family: $lora

      div.section-header
        p
          text-decoration: underline

        p:hover
          text-decoration: none

      div.marker-header
        display: flex
        justify-content: space-between
      div.marker-text
        margin: 3px 0 5px 5px
      p.marker
        margin: 2px 0 0


      div#calendar-cont
        margin-top: 10px
        div.rbc-month-view
          background-color: $pink
          border: 1px solid $black
          border-radius: 5px

          div.rbc-event
            background-color: $blue
            border: 1px solid $red
            color: $black

          div.rbc-month-header
            background-color: $yellow

        div.rbc-toolbar
          display: flex
          flex-direction: row-reverse
          justify-content: flex-end
          align-items: center

          span.rbc-toolbar-label
            font-size: 25px
            font-weight: bold
            flex-grow: 0
            margin-right: 15px

          span.rbc-btn-group
            background-color: $red
            border: 1px solid $black
            border-radius: 5px
            font-weight: bold

          span:nth-of-type(3)
            display: none !important

  #posts
    img
      float: right

    .upcoming
      ul.year-list
        margin: 10px 0 0 10px

      li.date-item
        margin: 3px 0 0 10px